// Generated by Framer (86ad780)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jUK0g_SDT", "dCtCqSlSX"];

const serializationHash = "framer-jTNFx"

const variantClassNames = {dCtCqSlSX: "framer-v-83mwh5", jUK0g_SDT: "framer-v-15x7pcq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "jUK0g_SDT", Open: "dCtCqSlSX"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jUK0g_SDT"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jUK0g_SDT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-15x7pcq", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"jUK0g_SDT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({dCtCqSlSX: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1yhshi7"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"KKM9UTLNN"} style={{rotate: 0}} variants={{dCtCqSlSX: {rotate: -180}}}><SVG className={"framer-ki06s2"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"xT48UNfzr"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 44 44\"><path d=\"M 9.429 17.286 L 22 29.857 L 34.571 17.286\" fill=\"transparent\" stroke-width=\"6.29\" stroke=\"#aaa\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={586664005} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jTNFx.framer-114wa0w, .framer-jTNFx .framer-114wa0w { display: block; }", ".framer-jTNFx.framer-15x7pcq { height: 44px; overflow: visible; position: relative; width: 44px; }", ".framer-jTNFx .framer-1yhshi7 { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-jTNFx .framer-ki06s2 { flex: none; height: 44px; left: calc(50.00000000000002% - 44px / 2); position: absolute; top: calc(50.00000000000002% - 44px / 2); width: 44px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dCtCqSlSX":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWlNRyzPs2: React.ComponentType<Props> = withCSS(Component, css, "framer-jTNFx") as typeof Component;
export default FramerWlNRyzPs2;

FramerWlNRyzPs2.displayName = "Caret Copy";

FramerWlNRyzPs2.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerWlNRyzPs2, {variant: {options: ["jUK0g_SDT", "dCtCqSlSX"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerWlNRyzPs2, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})